<template>
  <div class="register pt-30 mt-30">
    <!-- <pdf
     style="width: 100%;"
      :src="pdfSrc"
    ></pdf> -->
    <div class="center-float-center" v-for="i in 11" :key="i">
      <pdf style="width: 100%;" :src="pdfSrc" :page="i"></pdf>	
    </div>
  </div>
</template>
<script>
import pdf from 'vue-pdf'
export default{
  components: {
    pdf
  },
  data() {
    return{
      pdfSrc: 'policy.pdf'
    }

  },
  mounted() {
  },
  methods:{
    back() {
      this.$router.push('/')
    }
  }
}
</script>
<style>
</style>
